import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

//-------------------------------------------
// UI
import { Grid, Typography, Divider, Button } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./styles";

import Layout from "../../layout";
// import LoadingBar from "../../component/loadingBar";
// import { AuthComponent } from "../../component/auth";

//-------------------------------------------
// redux
import { useDispatch, useSelector } from "react-redux";

//-------------------------------------------
// api

import { CurrentAuthUiState, UserState } from "@psyrenpark/auth";

import { apiObject } from "../../api";

import ReactMarkdown from "react-markdown";
// import MarkdownEditor from "@uiw/react-markdown-editor";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";

const initMd = `


###   블로그 시작 20200910~ [7일차]

#  서버 개발자의 블로그 만들기 도전

1. 저의 이번달 목표는 저만의 블로그 만들기입니다. 
2. 생각보다 쉽지 않았던거 같습니다. 

\`\`\`그래도 목표가 생겨 열심히 할수있었던거 같습니다. 
이러한 이벤트를 통해 그동안 미뤄 두었던 올해의
목표를 달성한거 같아 기분이 좋습니다. 
\`\`\`
3. 앞으로도 꾸준히 업데이트 해 나가도록 하겠습니다. 
4. 영상은 퇴근후 작성하도록 하겠습니다.


###   블로그 시작 20200910~ [6일차]

#  서버 개발자의 블로그 만들기 도전

1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
2. 오늘도 생각보다는 많이 못했습니다. (1시간)
  - 관리자만 글 수정 할수 있도록 버그 해결.
  - 관리자가 아니더라도 글 가져오는것은 가능하도록 수정
3. 내알은 디자인 수정을 하도록 하겠습니다.
4. 파이팅!!!


###   블로그 시작 20200910~ [5일차]

#  서버 개발자의 블로그 만들기 도전

1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
2. 어제 일이 좀 생겨 작업이 거이 못했습니다. (30분)
  - 저장이 되도록 하였습니다. 새로고침해도 저장이 유지 됩니다.
  - 다만 로그인을 해야지 수정할수 있고 로그인 안해도 가져오는것은 가능하도록 수정필요
3. 내알은 디자인 수정을 하도록 하겠습니다.
4. 파이팅!!!


###   블로그 시작 20200910~ [4일차]

#  서버 개발자의 블로그 만들기 도전

1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
2. 서버와 관련된 작업을 진행했습니다. 여러모로 삽질을 했습니다.(3시간)
  - 간단한 통신이 되도록 했습니다.
  - 로그인, 회원가입, 어드민 가능하도록 작업
  - 서버에 저장 테스트
3. 내알은 마무리작업과 디자인 수정을 하도록 하겠습니다.
4. 파이팅!!!


###   블로그 시작 20200910~ [3일차]

#  서버 개발자의 블로그 만들기 도전

1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
2. 오늘은 md파일 에디터를 적용시켜보았습니다. 작업시간 (2시간)
  - 전의 라이브러리가 의도랑 맞지않아 심플한 버전으로 변경했습니다. 
  - 저장 가능하도록 서버 세팅중입니다.
  - 상단의 유저 모양 누르면 로그인 창 나오도록 
  - 로그인 기능 기초 구현 
3. 내알은 회원 가입과 서버통신 기초를 해보도록 하겠습니다.
4. 파이팅!!!


###   블로그 시작 20200910~ [2일차]

#  서버 개발자의 블로그 만들기 도전

1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
2. 오늘은 md파일 에디터를 적용시켜보았습니다. 작업시간 (3시간)
  - 수정버튼을 누르면 글을 작성할수 있는 에디터로 바뀝니다.
- 아직 내용저장 기능이 없어 새로고침하면 사라집니다. 서버에 저장하도록 수정 필요
- 글 입력이 좀 잘안되는 버그가 있습니다 수정필요
3. 내일은 내부에 글을 쓸수있도록 디자인을 만들어 보겠습니다.
4. 파이팅!!!


###   블로그 시작 20200910~ [1일차]

#  서버 개발자의 블로그 만들기 도전

>
블로그 시작 20200910~

1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
2. 오늘은 material-ui라는 라이브러리를 사용해서 기본 레이아웃을 만들어보았습니다.(작업시간 2시간)
3. 내일은 내부에 글을 쓸수있도록 디자인을 만들어 보겠습니다.
4. 파이팅!!!
`;

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    // marginTop: theme.spacing(),

    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const Blog = () => {
  const classes = useStyles();

  // const markdown = `
  //
  // `;
  const reducer = useSelector((state) => state.reducer);
  const dispatch = useDispatch();

  const [isEdit, setIsEdit] = useState(false);
  // const [isEdit, setIsEdit] = useState(true);

  const [markdown, setMarkdown] = useState(initMd);

  const mdParser = new MarkdownIt(/* Markdown-it options */);

  // Finish!
  const handleEditorChange = ({ html, text }) => {
    console.log("handleEditorChange", html, text);
    setMarkdown(text);
  };

  // const updateMarkdown = (editor, data, value) => {
  //   setMarkdown(value);
  // };

  const onChangeEdit = async (params) => {
    console.log("onChangeEdit -> reducer.useState", reducer.useState);

    if (reducer.userState !== UserState.SIGNED) {
      alert("수정 권한이 없습니다.");

      return;
    }

    var isEditTemp = !isEdit;

    setIsEdit(isEditTemp);

    if (!isEditTemp) {
      try {
        var blogData = await apiObject.updateBlog({
          langCode: "ko",
          pk: "blog",
          sk: "1",
          blog_md_data: markdown,
        });
        console.log("onChangeEdit -> blogData", blogData);
      } catch (error) {
        console.log("onChangeEdit -> error", error);
        // alert(error);
      }
    }
  };

  const getData = async (props) => {
    try {
      var blogData = await apiObject.getBlog({
        pk: "blog",
        sk: "1",
      });
      console.log("getData -> blogData", blogData);

      setMarkdown(blogData.data.Item.data);
    } catch (error) {
      console.log("getData -> error", error);
      // alert(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Layout>
      <div className={classes.heroContent}>
        {/* <div
      // style={{
      //   flex: 1,
      //   // overflow: "visible",
      //   // display: "auto",
      //   width: "auto",
      //   // overflowY: "hidden",
      //   // whiteSpace: "nowrap",
      // }}
      >

      </div> */}
        <div className={classes.heroButtons}>
          <Grid container justify="flex-end">
            {/* <Grid item>
            <Button variant="contained" color="primary">
              Add
            </Button>
          </Grid> */}
            <Grid item>
              <Button variant="outlined" onClick={onChangeEdit}>
                {!isEdit ? "수정하기" : "수정 중"}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Container maxWidth="lg">
          <Grid
            container
            // alignContent="center"
            // alignItems="center"
            justify="center"
            // direction="column"
            // zeroMinWidth={false}
            spacing={1}
            style={{
              // minHeight: "500",
              width: "100%",
              padding: 20,
              // marginRight: ,
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                Blog
              </Typography>
            </Grid>
            <Grid item xs={11}></Grid>

            <Grid item xs={12}>
              {isEdit ? (
                // <MarkdownEditor
                //   value={markdown}
                //   onChange={updateMarkdown}
                //   height="800"
                //   width="100%"
                // />
                <MdEditor
                  style={{
                    height: "600px",
                    // backgroundColor: "red"
                  }}
                  value={markdown}
                  renderHTML={(text) => mdParser.render(text)}
                  onChange={handleEditorChange}
                  // config={{
                  //   view: {
                  //     menu: true,
                  //     md: true,
                  //     html: true,
                  //     fullScreen: true,
                  //     hideMenu: true,
                  //   },
                  //   table: {
                  //     maxRow: 5,
                  //     maxCol: 6,
                  //   },
                  //   imageUrl: "https://octodex.github.com/images/minion.png",
                  //   syncScrollMode: ["leftFollowRight", "rightFollowLeft"],
                  // }}
                />
              ) : (
                <ReactMarkdown source={markdown} escapeHtml={false} />
              )}
            </Grid>
            {/* <Grid item xs={12}>
            <MarkdownEditor
              value={markdown}
              onChange={updateMarkdown}
              height="500"
              // width="100"
            />
          </Grid> */}

            {/* <Grid item xs={12}>
          <Typography variant="h1" component="h2">
            블로그 시작 20200910~
          </Typography>
          <Typography
            variant="h4"
            component="h4"
            style={{
              padding: 10,
              // margin: 10,
            }}
          >
            서버 개발자의 블로그 만들기 도전
          </Typography>

          <Typography
            variant="h6"
            component="h6"
            style={{
              padding: 1,
              // margin: 10,
            }}
          >
            1. 저의 이번달 목표는 저만의 블로그 만들기입니다.
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{
              padding: 1,
              // margin: 10,
            }}
          >
            2. 오늘은 material-ui라는 라이브러리를 사용해서 기본 레이아웃을
            만들어보았습니다.(작업시간 2시간)
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{
              padding: 1,
              // margin: 10,
            }}
          >
            3. 내일은 내부에 글을 쓸수있도록 디자인을 만들어 보겠습니다.
          </Typography>
          <Typography
            variant="h6"
            component="h6"
            style={{
              padding: 1,
              // margin: 10,
            }}
          >
            4. 파이팅!!!
          </Typography>
          <img
            src="/resources/blog_20200910/habit.png"
            alt=""
            styles={{
              height: "100%",
              width: "100%",
              padding: 10,
            }}
          />
        </Grid> */}

            {/* <img
          src="/resources/blog_20200910/habit.png"
          alt=""
          styles={{
            height: "100%",
            width: "100%",
            padding: 10,
          }}
        /> */}
          </Grid>
        </Container>
      </div>
    </Layout>
  );
};

export default Blog;
