import React from "react";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";

import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import InfoIcon from "@material-ui/icons/Info";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import BookIcon from "@material-ui/icons/Book";
import HomeIcon from "@material-ui/icons/Home";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

import { Provider, useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Sidebar = () => {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state.reducer);

  const history = useHistory();

  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  const handleDrawerClose = () => {
    dispatch({ type: "SET_DRAWER_OPEN_FLAG", payload: false });
  };

  const mianSideBar = [
    {
      key: "Home",
      icon: <HomeIcon />,
      onClick: () => {
        history.push("/");
      },
    },
    {
      key: "About me",
      icon: <InfoIcon />,
      onClick: () => {},
    },
    {
      key: "My Github",
      icon: <GitHubIcon />,
      onClick: () => {},
    },
    {
      key: "Send email",
      icon: <ContactMailIcon />,
      onClick: () => {},
    },
    {
      key: "Dev",
      icon: <SportsKabaddiIcon />,
      onClick: () => {},
    },
  ];

  const subMenuSideBar = [
    {
      key: "20200910",
      icon: <IndeterminateCheckBoxIcon />,
      onClick: () => {
        history.push("/blog_20200910");
      },
    },
    {
      key: "20200911",
      icon: <IndeterminateCheckBoxIcon />,
      onClick: () => {
        history.push("/blog_20200910");
      },
    },
  ];

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: reducer.drawerOpenFlag,
        [classes.drawerClose]: !reducer.drawerOpenFlag,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: reducer.drawerOpenFlag,
          [classes.drawerClose]: !reducer.drawerOpenFlag,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {mianSideBar.map((x, index) => (
          <ListItem
            button
            key={x.key}
            onClick={() => {
              // dispatch({ type: "SET_DRAWER_OPEN_FLAG", payload: true });

              if (x.onClick) {
                x.onClick();
              }
            }}
          >
            <ListItemIcon>{x.icon}</ListItemIcon>
            <ListItemText primary={x.key} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {subMenuSideBar.map((x, index) => (
          <ListItem
            button
            key={x.key}
            onClick={() => {
              // dispatch({ type: "SET_DRAWER_OPEN_FLAG", payload: true });

              if (x.onClick) {
                x.onClick();
              }
            }}
          >
            <ListItemIcon>{x.icon}</ListItemIcon>
            <ListItemText primary={x.key} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
