import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import ReactPlayer from "react-player";

import Layout from "../../layout";

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    // padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    // marginTop: theme.spacing(),

    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(8),
  },
  videoGrid: {
    backgroundColor: theme.palette.background.paper,
  },

  cardGrid: {
    // backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Album() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout>
      {/* Hero unit */}

      <div className={classes.heroContent}>
        <div className={classes.heroButtons}>
          <Grid container justify="flex-end">
            {/* <Grid item>
            <Button variant="contained" color="primary">
              Add
            </Button>
          </Grid> */}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size={"large"}
                onClick={() => {
                  history.push("/blog_20200910");
                }}
              >
                Move
              </Button>
            </Grid>
          </Grid>
        </div>

        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Psyren Park Blog
          </Typography>

          {/* <div
            style={{
              padding: 10,
            }}
          ></div> */}

          <Typography variant="h4" align="left" color="initial" paragraph>
            블로그 시작 20200910~ [7일차] <br />
          </Typography>

          <Typography variant="h5" align="left" color="initial" paragraph>
            서버 개발자의 블로그 만들기 도전 <br />
          </Typography>

          <Typography variant="h6" align="left" color="textSecondary" paragraph>
            1. 저의 이번달 목표는 저만의 블로그 만들기입니다. <br />
            2. 생각보다 쉽지 않았던거 같습니다. 그래도 목표가 생겨 열심히 할수
            있었던거 같습니다. 이러한 이벤트를 통해 그동안 미뤄 두었던 올해의
            목표를 달성한거 같아 기분이 좋습니다. <br />
            3. 앞으로도 꾸준히 업데이트 해 나가도록 하겠습니다. <br />
            4. 영상은 퇴근후 작성하도록 하겠습니다. <br />
          </Typography>
        </Container>
        <Grid item style={{ margin: 20 }}></Grid>
        <Grid
          container
          spacing={2}
          justify="center"
          className={classes.videoGrid}
        >
          <ReactPlayer url="https://www.youtube.com/watch?v=7aUIm-gQai4" />
        </Grid>
        <Grid item style={{ margin: 20 }}></Grid>
        <Grid
          container
          spacing={2}
          justify="center"
          className={classes.videoGrid}
        >
          <ReactPlayer url="https://www.youtube.com/watch?v=24UWzqUTebk" />
        </Grid>
        <Grid item style={{ margin: 20 }}></Grid>
      </div>

      {/* <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card} xs={12} sm={6} md={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image="https://source.unsplash.com/random"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    Heading
                  </Typography>
                  <Typography>
                    This is a media card. You can use this section to describe
                    the content.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary">
                    View
                  </Button>
                  <Button size="small" color="primary">
                    Edit
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container> */}
    </Layout>
  );
}
