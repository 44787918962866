export const tempTableData = [
  {
    no: 0,
    user_nm: "김",
    user_gender: "놤",
    user_phone: "01022334445",
    user_email: "piouy_@naver.com",
    birth: "2020-02-02",
    region: "지역",
    phone: "01022334455",
    state: "등급",
    type: "meet",
    date: "2020-02-02",
    min_now: "12/10",
    post_title: "제목",
    post_state: "등급",
    stadium_nm: "경기장이름",
    stadium_detail: "경기장 상세주소",
    stadium_info: "구장시설",
    payment_way: "신용카드",
    price: "10000",
    refund_account: "우리 1002-556-66932",
    coin: "3,250",
    refund_price: "3000",
    refund_state: 0,
    support_field: "신청",
    send_dt: "2020.06.06 16:00:00",
    send_user: "전체",
    info: "마카타타카마",
    type: "meet",
  },
  {
    no: 0,
    user_nm: "김",
    user_gender: "놤",
    user_phone: "01022334445",
    user_email: "piouy_@naver.com",
    birth: "2020-02-02",
    region: "지역",
    phone: "01022334455",
    state: "등급",
    date: "2020-02-02",
    min_now: "12/10",
    post_title: "제목",
    post_state: "등급",
    stadium_nm: "경기장이름",
    stadium_detail: "경기장 상세주소",
    stadium_info: "구장시설",
    payment_way: "신용카드",
    price: "10000",
    refund_account: "우리 1002-556-66932",
    coin: "3,250",
    refund_price: "3000",
    refund_state: 0,
    support_field: "신청",
    send_dt: "2020.06.06 16:00:00",
    send_user: "전체",
    info: "마카타타카마",
    type: "team-matching",
  },
  {
    no: 0,
    user_nm: "김",
    user_gender: "놤",
    user_phone: "01022334445",
    user_email: "piouy_@naver.com",
    birth: "2020-02-02",
    region: "지역",
    phone: "01022334455",
    state: "등급",
    date: "2020-02-02",
    min_now: "12/10",
    post_title: "제목",
    post_state: "등급",
    stadium_nm: "경기장이름",
    stadium_detail: "경기장 상세주소",
    stadium_info: "구장시설",
    payment_way: "신용카드",
    price: "10000",
    refund_account: "우리 1002-556-66932",
    coin: "3,250",
    refund_price: "3000",
    refund_state: 0,
    support_field: "신청",
    send_dt: "2020.06.06 16:00:00",
    send_user: "전체",
    info: "마카타타카마",
    type: "mercenary",
  },
  {
    no: 0,
    user_nm: "김",
    user_gender: "놤",
    user_phone: "01022334445",
    user_email: "piouy_@naver.com",
    birth: "2020-02-02",
    region: "지역",
    phone: "01022334455",
    state: "등급",
    date: "2020-02-02",
    min_now: "12/10",
    post_title: "제목",
    post_state: "등급",
    stadium_nm: "경기장이름",
    stadium_detail: "경기장 상세주소",
    stadium_info: "구장시설",
    payment_way: "신용카드",
    price: "10000",
    refund_account: "우리 1002-556-66932",
    coin: "3,250",
    refund_price: "3000",
    refund_state: 0,
    support_field: "신청",
    send_dt: "2020.06.06 16:00:00",
    send_user: "전체",
    info: "마카타타카마",
    type: "member",
  },
  {
    no: 0,
    user_nm: "김",
    user_gender: "놤",
    user_phone: "01022334445",
    user_email: "piouy_@naver.com",
    birth: "2020-02-02",
    region: "지역",
    phone: "01022334455",
    state: "등급",
    date: "2020-02-02",
    min_now: "12/10",
    post_title: "제목",
    post_state: "등급",
    stadium_nm: "경기장이름",
    stadium_detail: "경기장 상세주소",
    stadium_info: "구장시설",
    payment_way: "신용카드",
    price: "10000",
    refund_account: "우리 1002-556-66932",
    coin: "3,250",
    refund_price: "3000",
    refund_state: 0,
    support_field: "신청",
    send_dt: "2020.06.06 16:00:00",
    send_user: "전체",
    info: "마카타타카마",
    type: "lesson",
  },
];
