const { Api } = require("@psyrenpark/api");
// const axios = require("axios");

// const env = require("../env");
// var baseFileServerUrl = env.baseFileServerUrl;
// var projectName = env.projectName;
// var projectEnv = env.projectEnv;

var projectName = "pblog";
var projectEnv = "prod";

var v1Api = `${projectName}-${projectEnv}-api-v1`;
var v1Cdn = `${projectName}-${projectEnv}-cdn-v1`;

exports.apiObject = {
  getTest: () => {
    return Api.getAxios().get("https://www.google.com");
  },

  getTest2: ({ test, test2 }) => {
    return Api.getAxios().get("https://www.naver.com");
  },

  getTest3: ({ locale }, LoadingCallback) => {
    var apiName = v1Api;
    var path = "/test_test_test";
    var myInit = {
      headers: {},
      queryStringParameters: {},
    };
    return Api.get(apiName, path, myInit, LoadingCallback);
  },

  getTest4: ({ locale }, LoadingCallback) => {
    var apiName = v1Api;
    var path = "/blog";
    var myInit = {
      headers: {},
      queryStringParameters: {},
    };
    return Api.put(apiName, path, myInit, LoadingCallback);
  },

  //----------------------------------------------------
  // api

  getUser: ({ locale }, LoadingCallback) => {
    var apiName = v1Api;
    var path = "/api/users";
    var myInit = {
      headers: {},
      queryStringParameters: {},
    };
    return Api.get(apiName, path, myInit, LoadingCallback);
  },

  getBlog: ({ pk, sk }, LoadingCallback) => {
    var apiName = v1Cdn;
    var path = "/cdn/blog";
    var myInit = {
      headers: {},
      queryStringParameters: { pk, sk },
    };
    return Api.get(apiName, path, myInit, LoadingCallback);
  },

  updateBlog: ({ pk, sk, blog_md_data }, LoadingCallback) => {
    var apiName = v1Cdn;
    var path = "/cdn/blog";
    var myInit = {
      headers: {},
      queryStringParameters: {},
      body: {
        pk,
        sk,
        blog_md_data,
      },
    };
    return Api.put(apiName, path, myInit, LoadingCallback);
  },
};
