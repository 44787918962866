// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
// prettier-ignore
const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:25ba11d0-aea2-475b-b6ec-12be475dfb2d",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_K9Shg0TXb",
    "aws_user_pools_web_client_id": "5j7gq5r1a1qigjg525qb6a2kpb",
    "oauth": {
        "domain": "pblog-prod-auth.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost/",
        "redirectSignOut": "http://localhost/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "pblog-prod-api-v1",
            "endpoint": "https://uobso18gei.execute-api.ap-northeast-1.amazonaws.com/prod/v1",
            "region": "ap-northeast-1"
        },
        {
            "name": "pblog-prod-cdn-v1",
            "endpoint": "https://uobso18gei.execute-api.ap-northeast-1.amazonaws.com/prod/cdn/v1",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6pset7fa6bb3nj6gl5kq2bxrca.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "pblog-prod-file",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "pblog-prod-cms",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "pblog-prod-src"
};
export default awsmobile;
