import React, { useState, useEffect, useContext } from "react";
// import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import Wrapper from "./styles";

//-------------------------------------------
// redux
import { useDispatch, useSelector } from "react-redux";

//--------------------------------------------------
//
import {
  Auth,
  CurrentAuthUiState,
  AuthType,
  UserState,
} from "@psyrenpark/auth";

import { SignInComponent } from "./SignInComponent";
import { SignUpComponent } from "./SignUpComponent";
import { ConfirmSignUpComponent } from "./ConfirmSignUpComponent";
import { ForgotPasswordComponent } from "./ForgotPasswordComponent";
import { ConfirmForgotPasswordComponent } from "./ConfirmForgotPasswordComponent";
import { ChangePasswordComponent } from "./ChangePasswordComponent";

export const AuthComponent = (props) => {
  console.log({ props });
  const { setLoginSw, backgroundClickHandler } = props;

  const reducer = useSelector((state) => state.reducer);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Grid className="login_wrap">
        <Grid
          className="modal"
          onClick={() => {
            dispatch({
              type: "SET_CURRENT_AUTH_UI_STATE",
              payload: CurrentAuthUiState.SIGN_IN,
            });
            // setLoginSw(false);
            if (backgroundClickHandler) {
              backgroundClickHandler();
            }
          }}
        />
        <Grid className="login">
          <Grid className="login_inner">
            {!(
              reducer.currentAuthUiState ===
                CurrentAuthUiState.FORGOT_PASSWORD ||
              reducer.currentAuthUiState ===
                CurrentAuthUiState.CONFIRM_FORGOT_PASSWORD
            ) && (
              <Grid className="logo">
                <img src="/images/main/main_logo_img.png" alt="" />
              </Grid>
            )}
            {reducer.currentAuthUiState === CurrentAuthUiState.SIGN_IN && (
              <SignInComponent
                backgroundClickHandler={backgroundClickHandler}
              />
            )}
            {reducer.currentAuthUiState === CurrentAuthUiState.SIGN_UP && (
              <SignUpComponent />
            )}
            {reducer.currentAuthUiState ===
              CurrentAuthUiState.CONFIRM_SIGN_UP && <ConfirmSignUpComponent />}
            {reducer.currentAuthUiState ===
              CurrentAuthUiState.FORGOT_PASSWORD && <ForgotPasswordComponent />}
            {reducer.currentAuthUiState ===
              CurrentAuthUiState.CONFIRM_FORGOT_PASSWORD && (
              <ConfirmForgotPasswordComponent />
            )}
            {reducer.currentAuthUiState ===
              CurrentAuthUiState.CHANGE_PASSWORD && <ChangePasswordComponent />}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
};
