import styled from "styled-components";

const Wrapper = styled.div`
  & .content {
    padding-top: 80px;
  }
  flex-grow: 1;
`;

export default Wrapper;
