import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// import Main from "./pages/main/";
// import Movie from "./pages/movie";
// import Blog_202008_2 from "./pagses/blog_202008_2";

import Main from "./pages/main";
import Blog from "./pages/blog_20200910";
import Login from "./pages/login";

//-------------------------------------------
// redux
import { useDispatch, useSelector } from "react-redux";

//--------------------------------------------------
// auth
import {
  Auth,
  CurrentAuthUiState,
  AuthType,
  UserState,
} from "@psyrenpark/auth";

const Routes = () => {
  const reducer = useSelector((state) => state.reducer);
  const dispatch = useDispatch();

  const checkAuth = async () => {
    try {
      var auth = await Auth.currentSession();
      console.log("checkAuth -> auth", auth);

      var isAdmin = await Auth.isIncludeGroup("admin");

      console.log("checkAuth -> isAdmin", isAdmin);

      if (isAdmin) {
        dispatch({
          type: "SET_USER_STATE",
          payload: UserState.SIGNED,
        });
      } else {
        await Auth.signOut();
        dispatch({
          type: "SET_USER_STATE",
          payload: UserState.NOT_SIGN,
        });
      }
    } catch (error) {
      console.log("checkToLogin -> error", error);

      await Auth.signOut();

      dispatch({
        type: "SET_USER_STATE",
        payload: UserState.NOT_SIGN,
      });
    }
  };

  useEffect(() => {
    // 정규식 필요
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducer.userState]);

  return (
    <div>
      {reducer.userState === UserState.NOT_SIGN ? (
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/blog_20200910" exact component={Blog} />
            <Route path="/login" exact component={Login} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/blog_20200910" exact component={Blog} />
            <Route path="/login" exact component={Login} />
            {/* <Route path="/movie" exact component={Movie} />
            <Route path="/terms" exact component={Terms} /> */}

            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      )}
    </div>
  );
};

export default Routes;
